import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import onwardPin from '../images/logo192.png';
import Avatar from '@material-ui/core/Avatar/Avatar';
import onwardColors from '../lib/onwardColors';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ENV from '../constants/Env';
import Rating from '@material-ui/lab/Rating';
import { mediumDateTime } from '@onwardcare/core/lib/utils/date-format';

const getFeedback = async (slug: string) => {
  return await fetch(`${ENV.apiUrl}/feedback/rides/${slug}`);
};

const saveFeedback = async (slug: string, stars: number, comment: string) => {
  return await fetch(`${ENV.apiUrl}/feedback/rides/${slug}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ stars, feedback: comment }),
  });
};

const DEFAULT_ERROR_TEXT = 'Thank you for riding with Onward!';

export const FeedbackPage = () => {
  const styles = useStyles();
  const { slug } = useParams<{ slug: string }>();
  const [currentFeedback, setCurrentFeedback] = useState<null | {
    timestamp?: string;
    driver?: string;
    error?: string;
  }>(null);
  const [stars, setStars] = React.useState(0);
  const [comments, setComments] = React.useState('');
  const [feedBackResult, setFeedBackResult] = React.useState('');
  const rideTime = currentFeedback?.timestamp
    ? mediumDateTime(new Date(currentFeedback.timestamp), {
        // Since we are displaying this for the rider, there is no need to display
        // the time zone since we are rendering it in their local time.
        hideTimeZone: true,
      })
    : '';

  const sendFeedback = async () => {
    const result = await saveFeedback(slug, stars, comments)
      .then(r => r.json())
      .then<{ success: boolean }>(d => d);

    if (!result.success) {
      setCurrentFeedback({ error: 'Something went wrong.' });
    } else {
      setFeedBackResult('Thanks for your feedback!');
    }
  };

  useEffect(() => {
    getFeedback(slug)
      .then(result => result.json())
      .then(d => {
        if (d.success) {
          setCurrentFeedback({
            timestamp: d.timestamp,
            driver: d.driver,
          });
        } else {
          setCurrentFeedback({ error: d.error || DEFAULT_ERROR_TEXT });
        }
      })
      .catch(e => {
        setCurrentFeedback({ error: DEFAULT_ERROR_TEXT });
      });
  }, [slug]);

  let content = null;

  if (feedBackResult) {
    content = (
      <Typography component="h2" variant="h6">
        {feedBackResult}
      </Typography>
    );
  } else if (currentFeedback?.error) {
    content = (
      <Typography component="h2" variant="h6">
        {currentFeedback?.error}
      </Typography>
    );
  } else if (currentFeedback === null) {
    content = null;
  } else {
    content = (
      <>
        <Typography component="h2" variant="h6">
          Thank you for traveling with Onward on <b>{rideTime}</b>. We would
          like feedback on how {currentFeedback?.driver} did during the trip.
        </Typography>
        <div className={styles.divider} />

        <Typography component="legend" variant="h6">
          Rating
        </Typography>
        <Rating
          classes={{
            iconEmpty: styles.icon,
            iconFilled: styles.icon,
          }}
          name="hover-feedback"
          value={stars}
          size="large"
          precision={1}
          onChange={(event, newValue) => {
            newValue !== null && setStars(newValue);
          }}
        />
        <div className={styles.divider} />
        <TextField
          multiline
          autoFocus
          margin="dense"
          id="name"
          label="Comment"
          fullWidth
          value={comments}
          onChange={event => {
            setComments(event.target.value);
          }}
        />
        <div className={styles.divider} />

        <Button
          variant="contained"
          disabled={stars === 0}
          onClick={sendFeedback}
          color="primary"
          className={styles.button}
        >
          Save feedback
        </Button>
      </>
    );
  }
  return (
    <section className={styles.fullContainer}>
      <Avatar alt="Onward Pin" className={styles.avatar} src={onwardPin} />
      <Typography component="h1" variant="h4" className={styles.title}>
        Onward
      </Typography>

      <Container maxWidth="md">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={styles.content}
        >
          <div className={styles.divider} />
          {content}
        </Box>
      </Container>
    </section>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    color: onwardColors.onwardMedPurple,
    fontWeight: 700,
  },
  fullContainer: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 48,
  },
  content: {
    flexDirection: 'column',
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  button: {
    alignSelf: 'flex-end',
  },
}));
