import { makeStyles } from '@material-ui/core/styles';
import {
  timeZone as formatTimeZone,
  updateDateWithTimeString,
} from '@onwardcare/core/lib/utils/date-format';

const useStyles = makeStyles(theme => ({
  timeZoneText: {
    fontSize: 'small',
    color: theme.palette.text.hint,
  },
}));

export default function TimeZoneTimeText({
  date,
  time,
  timeZone,
}: {
  date: Date;
  time: string;
  timeZone?: string;
}) {
  const classes = useStyles();
  const formattedTimeZone = formatTimeZone(
    time === '' ? date : updateDateWithTimeString(date, time),
    { timeZone },
  );

  return (
    <div className={classes.timeZoneText}>Time is in {formattedTimeZone}</div>
  );
}
