import { add, differenceInSeconds } from 'date-fns';
import { getEnvironment } from '../constants/Env';
import {
  deleteCookie,
  readCookie,
  writeCookie,
} from '../storages/cookie-storage';
import AuthStorage from '@onwardcare/core/lib/auth/auth-storage';

const COOKIE_NAME = `${getPrefix()}onward-session`;
const COOKIE_EXPIRATION_NAME = `${COOKIE_NAME}-expiration_time`;

export interface OnwardToken {
  id: number;
  accessToken: string;
  client: string;
  expiry: string;
  uid: string;
}

interface OnwardSessionCookie {
  authenticated: OnwardToken | {};
}

/**
 * Gets the cookie prefix based on the environment.
 *
 * @returns The cookie prefix based on the environment.
 */
function getPrefix() {
  const environment = getEnvironment();
  return environment === 'production' ? '' : `${environment}-`;
}

/**
 * Checks to see if a cookie can be written.
 *
 * @param domainIp To write cookie for rider app local debug.
 * @returns Returns true if a cookie can be written.
 */
function canWriteCookie(domainIp?: string) {
  const cookieName = `${getPrefix()}onward-test`;
  writeCookie(cookieName, 'test', { domainIp });

  const cookieValue = readCookie(cookieName);
  const canWrite = cookieValue === 'test';

  deleteCookie(cookieName, { domainIp });

  return canWrite;
}

/**
 * Generates the expiration date and seconds.
 *
 * @returns Returns the expiration date and seconds.
 */
function generateExpiration() {
  const expirationDate = add(new Date(), { days: 30, hours: 12 });
  const expirationSeconds = differenceInSeconds(expirationDate, new Date());

  return { expirationDate, expirationSeconds };
}

/**
 * Gets the Onward token from storage.
 *
 * @returns The token if it exists, null otherwise.
 */
export function getToken(): OnwardToken | null {
  let value: string | null = null;

  if (canWriteCookie()) {
    value = readCookie(COOKIE_NAME);
  } else {
    value = AuthStorage.getItem(COOKIE_NAME);
  }

  const storedToken = value ? JSON.parse(decodeURIComponent(value)) : null;
  return storedToken ? { ...storedToken.authenticated } : null;
}

/**
 * Sets the Onward token in storage.
 *
 * @param token The token to set.
 * @param domainIp To use for rider app local debug.
 */
export function setToken(token: OnwardToken, domainIp?: string) {
  const storedToken: OnwardSessionCookie = {
    authenticated: { authenticator: 'authenticator:onward', ...token },
  };
  const value = encodeURIComponent(JSON.stringify(storedToken));
  const { expirationDate, expirationSeconds } = generateExpiration();

  if (canWriteCookie()) {
    writeCookie(COOKIE_NAME, value, {
      domainIp,
      expires: expirationDate,
    });
    writeCookie(COOKIE_EXPIRATION_NAME, expirationSeconds.toString(), {
      domainIp,
      expires: expirationDate,
    });
  } else {
    AuthStorage.setItem(COOKIE_NAME, value);
    AuthStorage.setItem(COOKIE_EXPIRATION_NAME, expirationSeconds.toString());
  }
}

/**
 * Deletes the Onward token from the cookie.
 *
 * @param domainIp To write cookie for rider app local debug.
 */
export function deleteToken(domainIp?: string) {
  const storedToken: OnwardSessionCookie = { authenticated: {} };
  const value = encodeURIComponent(JSON.stringify(storedToken));
  const { expirationDate, expirationSeconds } = generateExpiration();

  if (canWriteCookie()) {
    writeCookie(COOKIE_NAME, value, {
      domainIp,
      expires: expirationDate,
    });
    writeCookie(COOKIE_EXPIRATION_NAME, expirationSeconds.toString(), {
      domainIp,
      expires: expirationDate,
    });
  } else {
    AuthStorage.setItem(COOKIE_NAME, value);
    AuthStorage.setItem(COOKIE_EXPIRATION_NAME, expirationSeconds.toString());
  }
}

/**
 * Determines if the Onward token is expired.
 *
 * @param expiry The expiry date in epoch seconds.
 * @returns Returns true if the token is expired, false otherwise.
 */
export function isTokenExpired(expiry: string | undefined) {
  if (expiry === undefined || isNaN(+expiry)) {
    return true;
  }

  // The 0 sets the date to the epoch.
  const expiryDate = new Date(0);
  expiryDate.setUTCSeconds(+expiry);
  const now = new Date();

  return expiryDate < now;
}
