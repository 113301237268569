import { useMutation } from '@apollo/client';
import LayoutWithQuery from '../components/layout/LayoutWithQuery';
import { useToast } from '../components/layout/Toast';
import {
  RiderProfileForm,
  RiderTab,
} from '../components/riders/RiderProfileForm';
import { loader } from 'graphql.macro';
import { useTracking } from '../lib/analytics/Tracker';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  RiderProfileQueryQuery,
  QueryRiderArgs,
  UpdateRiderMutationMutation,
  UpdateRiderMutationMutationVariables,
  Rider,
  RemoveRiderPaymentMethodMutation,
  RemoveRiderPaymentMethodMutationVariables,
  UpdateRiderPaymentMethodMutationMutationVariables,
  UpdateRiderPaymentMethodMutationMutation,
} from 'generated/graphql';
import { ApolloError } from '@apollo/client/errors';
import { dateStringToISO } from '@onwardcare/core/lib/utils/date-format';
import { useRiderProfileNavigation } from '../hooks/useRiderProfileNavigation';

const RIDER_PROFILE_QUERY_2 = loader(
  '../data/queries/RiderProfileQuery.graphql',
);
const UPDATE_RIDER_MUTATION = loader(
  '../data/mutations/UpdateRiderMutation.graphql',
);
const REMOVE_PAYMENT_METHOD = loader(
  '../data/mutations/RemoveRiderPaymentMethod.graphql',
);
const UPDATE_PAYMENT_METHOD_MUTATION = loader(
  '../data/mutations/UpdateRiderPaymentMethodMutation.graphql',
);

export function RiderProfile() {
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [paymentError, setPaymentError] = useState<string | null>(null);
  const tracker = useTracking();
  const toast = useToast();

  const { generateRedirectUrl } = useRiderProfileNavigation();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const shouldCloseOnComplete =
    urlParams.get('shouldCloseOnSuccess') === 'true';
  const defaultTab = urlParams.get('tab') as RiderTab | null;

  useEffect(() => {
    tracker.track('Ride Profile Viewed');
  }, [tracker]);

  let { riderId } = useParams<{ riderId: string }>();

  const history = useHistory();

  const onRiderUpdated = (data: UpdateRiderMutationMutation) => {
    const error = data.updateRider?.error;

    if (error) {
      setUpdateError(error);
    } else if (shouldCloseOnComplete) {
      window.close();
    } else {
      const url = generateRedirectUrl(riderId);

      if (url) {
        history.push(url);
      } else {
        const doesAnyHistoryEntryExist = history.location.key !== undefined;

        if (doesAnyHistoryEntryExist) {
          history.goBack();
        } else {
          // Default to going back to the Rider Profiles grid.
          history.push('/riders');
        }
      }
    }
  };

  const onRiderUpdatedError = (error: ApolloError) => {
    if (error) {
      setUpdateError(error.message);
    }
  };

  const [updateRider, { loading }] = useMutation<
    UpdateRiderMutationMutation,
    UpdateRiderMutationMutationVariables
  >(UPDATE_RIDER_MUTATION, {
    // we're using an array of strings instead of objects as this method reuses the variables,
    // which is good for recent locations
    refetchQueries: [
      {
        query: RIDER_PROFILE_QUERY_2, //TODO: do we need this refetch at all?
        variables: { riderId },
      },
    ],
    onCompleted: onRiderUpdated,
    onError: onRiderUpdatedError,
  });

  const riderToPersist = (data: Partial<Rider>) => {
    const {
      profilePhoto,
      firstName: fName,
      lastName: lName,
      dob: date,
      ...rest
    } = data;

    let photo = null;
    if (profilePhoto?.length) {
      photo = profilePhoto[0];
    }

    let firstName = '';
    if (fName) {
      firstName = fName;
    }

    let lastName = '';
    if (lName) {
      lastName = lName;
    }

    // We have to convert the date string to ISO format.
    const dob = date ? dateStringToISO(date) : null;
    return { photo, firstName, lastName, dob, ...rest };
  };

  const onUpdate = (data: Partial<Rider>) => {
    tracker.track('Rider Profile Updated');
    if (!data.firstName || !data.lastName) {
      return;
    }
    // @ts-ignore
    updateRider({ variables: { riderId, rider: riderToPersist(data) } });
  };

  const onCardRemoved = (data: RemoveRiderPaymentMethodMutation) => {
    if (data.removeRiderPaymentMethod?.error) {
      onCardRemovedError(data.removeRiderPaymentMethod.error);
    } else {
      toast?.showMessage('Payment method removed successfully.');
    }
  };

  const onCardRemovedError = (error?: ApolloError | string) => {
    if (!error) return;

    setPaymentError(
      'This payment method can not be deleted. Please add a new valid card before deleting this one.',
    );
  };

  const [removeRiderPaymentMethod] = useMutation<
    RemoveRiderPaymentMethodMutation,
    RemoveRiderPaymentMethodMutationVariables
  >(REMOVE_PAYMENT_METHOD, {
    onCompleted: onCardRemoved,
    onError: onCardRemovedError,
  });

  const onRemoveCard = ({
    paymentMethodId,
    riderId,
  }: RemoveRiderPaymentMethodMutationVariables) => {
    tracker.track('Rider Payment Method Removed');
    removeRiderPaymentMethod({ variables: { paymentMethodId, riderId } });
  };

  const onUpdatePaymentMethodCompleted = (
    data: UpdateRiderPaymentMethodMutationMutation,
  ) => {
    if (data.updateRiderPaymentMethod?.error) {
      onUpdatePaymentMethodError(data.updateRiderPaymentMethod.error);
    } else {
      toast?.showMessage('Payment method updated successfully.');
    }
  };

  const onUpdatePaymentMethodError = (error: ApolloError | string) => {
    if (error) {
      setPaymentError(
        'Error updating your payment method.  If this error continues, please call 1-800-700-4797',
      );
    }
  };

  const [updatePaymentMethod] = useMutation<
    UpdateRiderPaymentMethodMutationMutation,
    UpdateRiderPaymentMethodMutationMutationVariables
  >(UPDATE_PAYMENT_METHOD_MUTATION, {
    onCompleted: onUpdatePaymentMethodCompleted,
    onError: onUpdatePaymentMethodError,
  });

  const onUpdatePaymentMethod = ({
    paymentMethodId,
    riderId,
    paymentMethod,
  }: UpdateRiderPaymentMethodMutationMutationVariables) => {
    tracker.track('Rider Payment Method Name Updated');

    updatePaymentMethod({
      variables: { paymentMethodId, riderId, paymentMethod },
    });
  };

  return (
    <LayoutWithQuery<RiderProfileQueryQuery, QueryRiderArgs>
      query={RIDER_PROFILE_QUERY_2}
      variables={{ riderId }}
      withRefetch
      renderView={(data, refetch) => {
        if (!data) {
          return null;
        }
        return (
          <RiderProfileForm<RiderProfileQueryQuery, QueryRiderArgs>
            submitting={loading}
            rider={data.rider}
            defaultTab={defaultTab}
            favoriteDrivers={data.favoriteDrivers}
            updateError={updateError}
            paymentError={paymentError}
            onUpdate={onUpdate}
            onRemoveCard={onRemoveCard}
            onUpdatePaymentMethod={onUpdatePaymentMethod}
            getRiderProfile={refetch}
          />
        );
      }}
    />
  );
}
