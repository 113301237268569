import React, { memo, useEffect } from 'react';
import { useStyles } from './styles';
import { CircularProgress, Grid, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { RideTimeHelperFormProps } from './props';
import { RideTimeSummary } from '../RideTimeSummary/RideTimeSummary';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { RideTimeError } from '../RideTimeError/RideTimeError';
import { LabelWithTooltip } from 'components/LabelWithTooltip';
import onwardColors from 'lib/onwardColors';
import {
  timeOnly,
  updateDateWithTimeString,
} from '@onwardcare/core/lib/utils/date-format';
import { addMinutes, subMinutes } from 'date-fns';
import TimeZoneTimeText from '../../../../TimeZoneTimeText';
import { useSessionContext } from '../../../../../contexts/session-context';

const RIDE_ESTIMATE_QUERY = loader(
  '../../../../../data/queries/RideEstimateQuery.graphql',
);

function calculatePickupTime({
  date,
  time,
  bufferMinutes,
  rideEstimate,
  operation,
}: {
  date: Date;
  time: string;
  bufferMinutes: number;
  rideEstimate: number;
  operation: 'ADD' | 'RMV';
}) {
  let arrDate;

  if (time) {
    arrDate = updateDateWithTimeString(date, time);
  }

  let updatedDateTime = new Date();

  if (arrDate) {
    if (operation === 'RMV') {
      updatedDateTime = subMinutes(arrDate, bufferMinutes + rideEstimate);
    } else if (operation === 'ADD') {
      updatedDateTime = addMinutes(arrDate, bufferMinutes + rideEstimate);
    }
  }

  return updatedDateTime;
}

const _RideTimeHelperForm: React.FC<RideTimeHelperFormProps> = ({
  defaultDate,
  defaultTime,
  arrivalDate,
  setArrivalDate,
  arrivalTime,
  setArrivalTime,
  bufferMinutes,
  setBufferMinutes,
  bufferMinutesOptions,
  ride,
  pickupTime,
  setPickupTime,
  setError,
}) => {
  const classes = useStyles();
  const { session } = useSessionContext();
  const timeZone = session?.account?.tzName ?? undefined;

  const filteredRide = {
    rideType: ride.rideType,
    startLocation: ride.startLocation,
    endLocation: ride.endLocation,
    requestedStartTime: ride.requestedStartTime,
    requestedEndTime: ride.requestedEndTime,
    transportType: ride.transportType,
    weightLbs: ride.weight,
    stairsCount: ride.stairsCount,
    oxygenRequired: ride.oxygenRequired,
    contactPrecautionsRequired: ride.contactPrecautionsRequired,
  };

  const { loading, error, data } = useQuery(RIDE_ESTIMATE_QUERY, {
    variables: { ride: filteredRide },
  });

  // Convert this to date to use on calculate pickup time
  useEffect(() => {
    const calculateArrivalDate = () => {
      if (
        !!data?.rideEstimate?.estimatedDurationMinutesNoRound &&
        defaultDate !== null &&
        defaultTime !== null
      ) {
        const date = calculatePickupTime({
          date: defaultDate,
          time: defaultTime,
          bufferMinutes,
          rideEstimate: parseInt(
            data.rideEstimate.estimatedDurationMinutesNoRound,
          ),
          operation: 'ADD',
        });
        setArrivalDate(date);
        setArrivalTime(
          timeOnly(date, { timeFormat: '24', hideTimeZone: true }),
        );
      }
    };

    calculateArrivalDate();
  }, [
    data?.rideEstimate?.estimatedDurationMinutesNoRound,
    setArrivalDate,
    setArrivalTime,
    defaultDate,
    defaultTime,
    bufferMinutes,
  ]);

  useEffect(() => {
    if (
      !!data?.rideEstimate?.estimatedDurationMinutesNoRound &&
      arrivalDate !== null &&
      arrivalTime !== null
    ) {
      const date = calculatePickupTime({
        date: arrivalDate,
        time: arrivalTime,
        bufferMinutes: bufferMinutes,
        rideEstimate: parseInt(
          data.rideEstimate.estimatedDurationMinutesNoRound,
        ),
        operation: 'RMV',
      });
      setPickupTime(date);
    }
  }, [
    arrivalDate,
    arrivalTime,
    bufferMinutes,
    data?.rideEstimate?.estimatedDurationMinutesNoRound,
    setPickupTime,
  ]);

  if (error) {
    setError(error);
    return (
      <RideTimeError
        error=" There was an error generating your estimate. Please contact Onward at
  1-800-700-4797."
      />
    );
  }
  if (loading) {
    return (
      <div className={classes.root}>
        <CircularProgress size={24} color="secondary" />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.section}>
        <Grid item xs={7}>
          <LabelWithTooltip
            labelText="Arrival Day"
            tooltipText=""
            labelStyle={{
              fontSize: '14px',
              color: onwardColors.onwardLightGray,
            }}
          />
          <DatePicker
            id="arrivalDay"
            disablePast
            onChange={date => setArrivalDate(date as Date)}
            value={arrivalDate}
          />
        </Grid>
        <Grid item xs={5}>
          <LabelWithTooltip
            labelText="Arrival Time"
            tooltipText=""
            labelStyle={{
              fontSize: '14px',
              color: onwardColors.onwardLightGray,
            }}
          />
          <TextField
            id="arrivalTime"
            type="time"
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            onChange={date => {
              setArrivalTime(date.target.value);
            }}
            defaultValue={arrivalTime}
            value={arrivalTime}
          />
          {arrivalDate && arrivalTime && (
            <TimeZoneTimeText
              date={arrivalDate}
              time={arrivalTime}
              timeZone={timeZone}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.section}></Grid>
        <Grid item xs={12} className={classes.section}>
          <LabelWithTooltip
            labelText="Buffer Time"
            labelStyle={{
              fontSize: '14px',
              color: onwardColors.onwardLightGray,
            }}
            tooltipText="Buffer time allows for additional time for parking, loading or unloading, or finding the room or specific location."
          />
          <TextField
            id="bufferTime"
            select
            value={bufferMinutes}
            onChange={event => setBufferMinutes(parseInt(event.target.value))}
            SelectProps={{
              native: true,
            }}
            className={classes.bufferTimeTextField}
          >
            {bufferMinutesOptions.map(option => (
              <option key={option} value={option}>
                {option} minutes
              </option>
            ))}
          </TextField>
        </Grid>
        <RideTimeSummary
          pickupTime={pickupTime}
          arrivalDate={arrivalDate ?? new Date()}
          arrivalTime={arrivalTime}
          rideEstimate={parseInt(
            data.rideEstimate.estimatedDurationMinutesNoRound,
          )}
          bufferMinutes={bufferMinutes}
        />
      </Grid>
    </div>
  );
};

export const RideTimeHelperForm = memo(_RideTimeHelperForm);
