import { useQuery } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { loader } from 'graphql.macro';
import { RideTimeError } from './rideFormComponents/RideTimeHelper/RideTimeError/RideTimeError';
import {
  convertLocalToISOInTimeZone,
  timeOnly,
} from '@onwardcare/core/lib/utils/date-format';
import { useSessionContext } from 'contexts/session-context';

const RIDE_ESTIMATE_QUERY = loader(
  '../../data/queries/RideEstimateQuery.graphql',
);

const RideEstimate = ({
  requestedStartTime,
  requestedEndTime,
  rideType,
  startLocation,
  endLocation,
  transportType,
  weightLbs,
  stairsCount,
  oxygenRequired,
  contactPrecautionsRequired,
  arrivalBufferMinutes,
  isFlexibleRide,
}) => {
  const { session } = useSessionContext();
  const timeZone = session?.account?.tzName ?? undefined;

  let ride = {
    // Since we are using the time that the user has typed in, we need to first
    // make sure that we convert it as if it was done in the specified timezone,
    // then we convert it to an ISO string.
    requestedStartTime: convertLocalToISOInTimeZone(
      requestedStartTime,
      timeZone,
    ),
    rideType,
    startLocation,
    endLocation,
    transportType,
    stairsCount,
    weightLbs: weightLbs || 0,
    oxygenRequired,
    contactPrecautionsRequired,
    arrivalBufferMinutes,
    flexibleRide: isFlexibleRide,
  };

  if (ride.rideType === 'roundtrip' && requestedEndTime) {
    // Since we are using the time that the user has typed in, we need to first
    // make sure that we convert it as if it was done in the specified timezone,
    // then we convert it to an ISO string.
    ride.requestedEndTime = convertLocalToISOInTimeZone(
      requestedEndTime,
      timeZone,
    );
  }

  const { loading, error, data } = useQuery(RIDE_ESTIMATE_QUERY, {
    variables: { ride },
  });

  if (loading) return <CircularProgress size={24} color="secondary" />;
  if (error)
    return (
      <RideTimeError
        error=" There was an error generating your estimate. Please contact Onward at
  1-800-700-4797."
      />
    );

  if (data.rideEstimate.estimatedCostFormatted === 'N/A') return null;

  return (
    <Typography component="span" variant="body1">
      <Box display="flex">
        <Box flexGrow={1} fontWeight="fontWeightMedium">
          Fare Estimate:
        </Box>
        <Box fontWeight="fontWeightMedium">
          {data.rideEstimate.estimatedCostFormatted}
        </Box>
      </Box>
      {transportType === 'companion' && data.rideEstimate.estimatedEndTime ? (
        <Box display="flex">
          <Box flexGrow={1} fontWeight="fontWeightMedium">
            End Time:
          </Box>
          <Box fontWeight="fontWeightMedium">
            {timeOnly(data.rideEstimate.estimatedEndTime, { timeZone })}
          </Box>
        </Box>
      ) : null}
    </Typography>
  );
};

export default RideEstimate;
