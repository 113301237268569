import { useEffect, useState } from 'react';
import PageLoadingSpinner from '../components/ehr/PageLoadingSpinner';
import { useHistory } from 'react-router-dom';
import {
  CreateRiderInput,
  useAddCustodianRiderMutationMutation,
} from '../generated/graphql';
import EhrErrorMessage from 'components/ehr/EhrErrorMessage';
import DebugInformation from 'components/ehr/DebugInfo';
import ErrorHandler from '../lib/error-handler';
import { TokenResponse, useEhrContext } from '../contexts/ehr-context';
import { ensureErrorIsJson } from '../lib/json-error';
import { dateStringToISO } from '@onwardcare/core/lib/utils/date-format';

type PageState =
  | 'loading'
  | 'missingContextFields'
  | 'invalid'
  | 'error'
  | 'success';

/**
 * Decodes a string value that may have been encoded by the EHR and then replaces
 * any '+' characters with spaces.
 *
 * @param value The string value to decode.
 * @returns Returns the decoded string.
 */
function decodeString(value?: string | null) {
  return decodeURIComponent(value ?? '').replace(/\+/g, ' ');
}

/**
 * Once we have a custodian for the EHR user and we have made the FHIR calls, we
 * land on this page. From here, we will create a new rider using the
 * information we get from the launch context and FHIR calls.
 */
export default function ChooseRider() {
  const { ehrToken, ehrSession } = useEhrContext();
  const history = useHistory();
  const [state, setState] = useState<PageState>('loading');
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const onRiderCreated = (data: any) => {
    const error = data.addCustodianRider.error;

    if (error) {
      onRiderCreatedError(error);
    } else {
      const riderId = data?.addCustodianRider?.rider?.id || null;

      if (riderId === null) {
        const errorMessage = 'Could not create rider';
        ErrorHandler.captureMessage(
          `[Epic EHR] - Choose Rider - Error creating rider: ${errorMessage}`,
        );
        setState('error');
        setError(errorMessage);
      } else {
        setState('success');
        history.push(`/riders/${riderId}?tab=rides`, { from: 'ehr' });
      }
    }
  };

  const onRiderCreatedError = (error: any) => {
    const jsonError = ensureErrorIsJson(error);
    ErrorHandler.captureMessage(
      `[Epic EHR] - Choose Rider - Error creating rider: ${jsonError.message}`,
    );
    setState('error');
    setError(jsonError.message);
  };

  const [addCustodianRider] = useAddCustodianRiderMutationMutation({
    onCompleted: onRiderCreated,
    onError: onRiderCreatedError,
  });

  const missingRequiredFields = (tokenResponse: TokenResponse | null) => {
    const requiredFields = [
      'patientFirstName',
      'patientLastName',
      'patientDOB',
    ];
    const missing: string[] = [];

    requiredFields.forEach(field => {
      if (!Object.keys(tokenResponse || {}).includes(field)) {
        missing.push(field);
      }
    });

    if (missing.length > 0) {
      ErrorHandler.captureMessage(
        `[Epic EHR] - Choose Rider - Missing required fields: ${missing.join(
          ', ',
        )}`,
      );
      setState('missingContextFields');
      setMissingFields(missing);
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!ehrToken || !ehrSession?.ehrSessionId) {
      return;
    }

    if (missingRequiredFields(ehrToken)) {
      return;
    }

    // Can be sent as an encoded string (example: D%C3%ADas), so decode it first.
    const firstName = decodeString(ehrToken.patientFirstName);
    const lastName = decodeString(ehrToken.patientLastName);
    // Can be sent as an encoded string (example: 1%2F1%2F1990), so decode it first.
    const rawDob = decodeString(ehrToken.patientDOB);
    // Convert the date to be in the format that we expect.
    const dob = dateStringToISO(rawDob);

    if (dob === null) {
      ErrorHandler.captureMessage(
        `[Epic EHR] - Choose Rider - The DOB is invalid: '${rawDob}'`,
      );
      setState('invalid');
      setError(`The value of "${rawDob}" for the DOB field is not valid.`);
      return;
    }

    const rider: CreateRiderInput = {
      firstName,
      lastName,
      dob,
    };

    addCustodianRider({
      variables: {
        rider,
        ehrSessionId: ehrSession?.ehrSessionId,
      },
    });
  }, [addCustodianRider, ehrToken, ehrSession?.ehrSessionId]);

  return (
    <>
      {(state === 'loading' || state === 'success') && <PageLoadingSpinner />}

      {state === 'missingContextFields' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>
            Launch context is missing fields
          </EhrErrorMessage.Title>
          <EhrErrorMessage.Body>
            <p>
              Onward requires that this page is loaded in the context of a
              patient's chart or encounter.
            </p>

            {ehrSession?.testSession && (
              <>
                <p>The following fields are missing from the launch context:</p>

                <ul>
                  {missingFields.map(field => (
                    <li key={field}>{field}</li>
                  ))}
                </ul>
              </>
            )}

            <DebugInformation tokenResponse={ehrToken} />
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}

      {state === 'invalid' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>Invalid DOB</EhrErrorMessage.Title>

          <EhrErrorMessage.Body>
            {error && <p>{error}</p>}

            <DebugInformation tokenResponse={ehrToken} />
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}

      {state === 'error' && (
        <EhrErrorMessage>
          <EhrErrorMessage.Title>Could not create rider</EhrErrorMessage.Title>

          <EhrErrorMessage.Body>
            <p>There was an issue trying to create the rider.</p>

            {ehrSession?.testSession && (
              <p>Error: {error || 'Unknown error'}</p>
            )}

            <DebugInformation tokenResponse={ehrToken} />
          </EhrErrorMessage.Body>
        </EhrErrorMessage>
      )}
    </>
  );
}
